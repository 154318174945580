<template>
  <div class="about" data-view>
    <div class="about__hero hero">
      <div class="container">
        <h1 class="hero__title title" v-animate-onscroll="'animated backIn'">
          О сервисе
        </h1>
        <div class="hero__text" v-animate-onscroll="'animated backIn delay-05'">
          <p class="p-3">
            Факторинговый маркетплейс EdiFin разработан компанией
            <a href="https://ediweb.com">Ediweb</a>
            и&nbsp;является частью её&nbsp;цифровой b2b-экосистемы. Решение
            помогает облегчить и&nbsp;упростить взаимодействие бизнеса
            и&nbsp;факторинговых компаний. EdiFin избавляет
            от&nbsp;необходимости отправлять множество однотипных запросов,
            проходить сложные и&nbsp;длительные проверки, тратить время
            и&nbsp;силы на&nbsp;бюрократические процессы.
          </p>
          <p class="p-3">
            Мы&nbsp;делаем факторинг проще, понятнее и&nbsp;доступнее.
          </p>
        </div>
      </div>
      <div class="hero__graph">
        <v-graph
          :scheme="{
            scatter: 0.25,
            angle: 20,
            offset: 0.0,
            background: {
              start: 'rgba(206, 229, 139, 0.16)',
              stop: 'rgba(206, 229, 139, 0)',
            },
            points: {
              count: 8,
              background: 'rgba(235, 248, 223, 0)',
              border: {
                width: 4,
                color: 'rgba(179, 221, 136, 0)',
              },
              radius: 6,
            },
            line: {
              width: 2,
              color: 'rgba(179, 221, 136, 0)',
            },
            animation: {
              duration: 500,
              delay: 2000,
            },
          }"
        />
        <v-graph
          :scheme="{
            scatter: 0.25,
            angle: 15,
            offset: 0.0,
            background: {
              start: 'rgba(118, 200, 120, 0.16)',
              stop: 'rgba(118, 200, 120, 0)',
            },
            points: {
              count: 8,
              background: 'rgba(235, 248, 223, 1)',
              border: {
                width: 4,
                color: 'rgba(179, 221, 136, 1)',
              },
              radius: 6,
            },
            line: {
              width: 2,
              color: 'rgba(179, 221, 136, 1)',
            },
            animation: {
              duration: 500,
              delay: 2000,
            },
          }"
        />
        <v-graph
          :scheme="{
            scatter: 0.25,
            angle: 10,
            offset: 0.0,
            background: {
              start: 'rgba(21, 164, 161, 0.16)',
              stop: 'rgba(21, 164, 161, 0)',
            },
            points: {
              count: 7,
              background: 'rgba(214, 244, 237, 0)',
              border: {
                width: 4,
                color: 'rgba(174, 231, 219, 0)',
              },
              radius: 6,
            },
            line: {
              width: 2,
              color: 'rgba(174, 231, 219, 0)',
            },
            animation: {
              duration: 500,
              delay: 2000,
            },
          }"
        />
      </div>
    </div>
    <div class="about__steps steps">
      <div class="container">
        <div class="steps__container">
          <div class="h1 steps__title" v-animate-onscroll="'animated backIn'">
            Как мы работаем
          </div>
          <div class="steps__wrapper">
            <div class="step" v-animate-onscroll="'animated backIn'">
              <div class="step__img m-hide">
                <img src="@/assets/img/about/icons/01.svg" alt="" />
              </div>
              <div class="step__title">Выбор и&nbsp;отправка запроса</div>
              <p class="step__text p-4">
                Вы&nbsp;выбираете подходящую под вашу ситуацию факторинговую
                услугу и&nbsp;отправляете запрос.
              </p>
              <svg
                class="step__line step__line--one svg-line"
                width="251"
                height="44"
                viewBox="0 0 251 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <mask id="theMask" class="mask">
                    <path
                      class="path"
                      d="M2 2C82.7681 42 197.816 42 249 42"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke="#fff"
                    />
                  </mask>
                </defs>

                <g mask="url(#theMask)">
                  <path
                    class="path"
                    d="M2 2C82.7681 42 197.816 42 249 42"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke="#fff"
                    stroke-dasharray="4 8"
                  />
                </g>
              </svg>
            </div>

            <div class="step" v-animate-onscroll="'animated backIn delay-05'">
              <div class="step__img m-hide">
                <img src="@/assets/img/about/icons/02.svg" alt="" />
              </div>
              <div class="step__title">Получение предложения</div>
              <p class="step__text p-4">
                Мы&nbsp;передаем его факторинговым партнёрам, которые проводят
                предварительную оценку и&nbsp;отправляют вам&nbsp;индивидуальное
                предложение.
              </p>
              <svg
                class="step__line step__line--two svg-line"
                width="252"
                height="52"
                viewBox="0 0 252 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <mask id="theMaskTwo" class="mask">
                    <path
                      class="path"
                      d="M2 50C32.6118 34 125.068 2 250 2"
                      stroke="#fff"
                      stroke-width="3"
                      stroke-linecap="round"
                    />
                  </mask>
                </defs>

                <g mask="url(#theMaskTwo)">
                  <path
                    class="path"
                    d="M2 50C32.6118 34 125.068 2 250 2"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-dasharray="4 8"
                  />
                </g>
              </svg>
            </div>

            <div class="step" v-animate-onscroll="'animated backIn delay-10'">
              <div class="step__img m-hide">
                <img src="@/assets/img/about/icons/03.svg" alt="" />
              </div>
              <div class="step__title">Соглашение и&nbsp;финансирование</div>
              <p class="step__text p-4">
                Далее вы&nbsp;заключаете соглашение и&nbsp;получаете
                финансирование. EdiFin не&nbsp;берёт комиссий
                и&nbsp;дополнительных платежей.
              </p>
            </div>
          </div>
          <p
            class="steps__desclamer reg"
            v-animate-onscroll="'animated backIn delay-05'"
          >
            Генеральный партнёр сервиса&nbsp;&mdash; ООО&nbsp;«СмартФакт»,
            Адрес:&nbsp;123001, г.&nbsp;Москва, Трехпрудный&nbsp;пер.,
            д.&nbsp;9, стр.&nbsp;2, оф.&nbsp;101. ИНН&nbsp;7707837251
          </p>
        </div>
      </div>
    </div>
    <div class="about__more">
      <div class="container">
        <el-row :gutter="24">
          <el-col
            :span="10"
            class="about__more-img m-hide"
            v-animate-onscroll="'animated backIn'"
          >
            <v-video
              required
              autoplay
              muted
              loop
              poster="/video/posters/about/01.png"
              video="/video/about/01.webm"
            />
          </el-col>
          <el-col :span="14" :xs="24" class="about__more-text">
            <h2 v-animate-onscroll="'animated backIn'">О&nbsp;разработчике</h2>
            <p class="p-4" v-animate-onscroll="'animated backIn delay-05'">
              <a href="https://ediweb.com">Ediweb</a>&nbsp;&mdash; разработчик
              цифровой b2b-экосистемы, оператор электронного документооборота
              и&nbsp;интегратор. Мы&nbsp;помогаем оцифровать
              и&nbsp;синхронизировать работу с&nbsp;данными на&nbsp;всех этапах
              поставок: от&nbsp;закупок и&nbsp;продаж до&nbsp;логистики
              и&nbsp;управления финансовыми потоками.
            </p>
            <p class="p-4" v-animate-onscroll="'animated backIn delay-10'">
              Решения <a href="https://ediweb.com">Ediweb</a> предназначены для
              промышленных и&nbsp;транспортных компаний, торговых сетей,
              дистрибьюторов, финансовых организаций, телекома
              и&nbsp;государственных органов.
            </p>
            <p class="p-4" v-animate-onscroll="'animated backIn delay-15'">
              Офисы расположены в&nbsp;Санкт-Петербурге и&nbsp;Смоленске.
            </p>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="about__map">
      <div class="container">
        <el-row>
          <el-col :span="10" :xs="24">
            <div
              class="about__map-title h2"
              v-animate-onscroll="'animated backIn'"
            >
              <a href="https://ediweb.com" style="color: white">Ediweb</a>
              &nbsp;&mdash; <span>один из&nbsp;лидеров</span> электронного
              факторинга
            </div>
            <p
              class="about__map-text p-3 reg"
              v-animate-onscroll="'animated backIn delay-05'"
            >
              В&nbsp;среднем, факторы, работающие
              с&nbsp;нашими&nbsp;SCF-решениями, одобряют до&nbsp;98%&nbsp;заявок
              на&nbsp;финансирование.
            </p>
          </el-col>
          <el-col :span="14" :xs="24"></el-col>
        </el-row>
        <div
          class="about__map-figures"
          v-animate-onscroll="'animated backIn delay-10'"
        >
          <div class="about__map-figure">
            <div class="h1">960 <span class="h2">млрд&nbsp;руб</span></div>
            <p class="p-4 reg">Объём профинансированных поставок</p>
          </div>
          <div class="about__map-figure">
            <div class="h1">6,9 <span class="h2">млн</span></div>
            <p class="p-4 reg">Количество успешных факторинговых сделок</p>
          </div>
        </div>
      </div>
    </div>
    <div class="about__docs">
      <div class="container">
        <h2 class="about__docs-title" v-animate-onscroll="'animated backIn'">
          Лицензии и&nbsp;сертификаты
        </h2>
        <p
          class="p-3 about__docs-text"
          v-animate-onscroll="'animated backIn delay-05'"
        >
          <a href="https://ediweb.com">Ediweb</a> является доверенным оператором
          электронного документооборота и&nbsp;имеет все необходимые лицензии
          и&nbsp;сертификаты от&nbsp;контролирующих органов.
        </p>
        <div class="about__docs-carousel">
          <carousel
            v-animate-onscroll="'animated backIn delay-10'"
            loop
            navigationEnabled
            :paginationEnabled="false"
            :perPageCustom="[
              [320, 1],
              [570, 3],
              [876, 4],
            ]"
            :navigationClickTargetSize="10"
            navigationNextLabel='<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 33L17 17L1 1" stroke="#AFC1B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            navigationPrevLabel='<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 33L1 17L17 1" stroke="#AFC1B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
          >
            <slide v-for="(image, imageIndex) in imgs" :key="imageIndex">
              <div>
                <img :src="image" alt="" @click="showMultiple(imageIndex)" />
              </div>
            </slide>
          </carousel>

          <vue-easy-lightbox
            escDisabled
            moveDisabled
            loop
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
          >
          </vue-easy-lightbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import VueEasyLightbox from "vue-easy-lightbox";

const meta = {
  title: "О сервисе",
  description: "Поможем получить финансирова­ние ваших неоплачен­ных счетов",
};

export default {
  components: {
    Carousel,
    Slide,
    VueEasyLightbox,
    "v-video": () => import("@/components/Video"),
    "v-graph": () => import("@/components/Graph"),
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "description",
        content: meta.description,
      },
      {
        name: "og:description",
        content: meta.description,
      },
    ],
  },
  data() {
    return {
      title: "О сервисе",
      imgs: [
        require("@/assets/img/docs/01.jpg"),
        require("@/assets/img/docs/02.jpg"),
        require("@/assets/img/docs/03.jpg"),
        require("@/assets/img/docs/04.jpg"),
      ],
      visible: false,
      index: 0,
    };
  },
  methods: {
    showMultiple(idx) {
      this.index = idx;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.steps {
  &__container {
    margin: 0 -56px;
    padding: $space-80;
    border-radius: $radius-lg;
    background: $bg-teal-to-green;

    @include mobile {
      margin: 0;
      padding: $space-48 $space-24 $space-56;
    }
  }

  &__title {
    text-align: center;
    color: $white;

    @include mobile {
      margin-bottom: $space-40;
      text-align: left;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__desclamer {
    margin-top: 0px;
    color: $white;

    @include mobile {
      margin-top: $space-16;
    }
  }
}

.step {
  $self: &;
  width: 33%;
  position: relative;
  margin: $space-24 $space-32 $space-80 $space-32;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  text-align: center;
  margin-top: -80px;

  @include mobile {
    width: 100%;
    margin: 0 0 $space-32;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:nth-of-type(2n) {
    margin-top: 80px;

    @include mobile {
      margin-top: 0px;
    }
  }

  &__img {
    margin-bottom: $space-32;
    background: rgba($teal-200, 0.24);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: $radius-full;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.3;
  }

  &__line {
    position: absolute;
    top: 0;
    right: 0;

    @include tablet {
      display: none;
    }

    &--one {
      top: 4.5rem;
      right: -10rem;
    }

    &--two {
      top: -1rem;
      right: -10rem;
    }
  }
}

.step.animated {
  .step__line {
    &--one {
      .mask {
        stroke-dasharray: 260px;
        stroke-dashoffset: 260px;
        animation: dash 0.25s linear 0.5s forwards;
      }
    }

    &--two {
      .mask {
        stroke-dasharray: 260px;
        stroke-dashoffset: 260px;
        animation: dash 0.25s linear 1s forwards;
      }
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.about {
  &__steps {
    margin: $space-80 0 $space-120;

    @include tablet {
      margin: $space-16 0 $space-56;
    }
  }

  &__more {
    margin-bottom: $space-120;

    @include tablet {
      margin-bottom: $space-56;
    }

    &-img {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-text {
      p {
        color: $gray-700;
      }
    }
  }

  &__map {
    padding: $space-120 0 $space-120;
    // background-color: #107f96; цвет фона под карту?
    background-color: $teal-700;
    color: $white;

    @include tablet {
      padding: $space-56 0;
    }

    &-title {
      margin-bottom: $space-32;
      max-width: 330px;

      @include tablet {
        max-width: none;
      }

      span {
        color: $green-200;
      }
    }

    &-text {
      opacity: 0.8;
    }

    &-img {
      @include tablet {
        margin: $space-48 0 $space-24;
      }
    }

    &-figures {
      display: flex;

      @include mobile {
        flex-direction: column;
      }
    }

    &-figure {
      margin-right: $space-120;
      color: $white;

      @include tablet {
        margin-top: $space-80;
      }

      @include mobile {
        margin-top: $space-24;
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      div {
        margin-bottom: 0;
      }

      p {
        color: $white;
        opacity: 0.8;
      }
    }
  }

  &__docs {
    margin: $space-120 0;

    @include tablet {
      margin: $space-56 0;
    }

    &-title {
      margin-bottom: 32px;
      text-align: center;
    }

    &-text {
      max-width: 870px;
      margin: 0 auto;
      text-align: center;
    }

    &-carousel {
      margin-top: $space-56;

      .VueCarousel-slide > div {
        position: relative;
        transition: transform $trans-anim;

        &:hover {
          transform: scale(1.06);
        }
      }
    }
  }
}
</style>
